@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import '../src/assets/base.scss';

$black: rgba(0, 0, 0, 0.87);

.App {
  text-align: center;
  height: 100vh;
}

.router {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

body {
  background: #efefef;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: inherit;
  font-size: 16px;
  font-family: 'Poppins';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.toolbar {
  left: 0px;
  margin: 0px;
  padding: 0px;
}

.menuList .MuiToolbar-gutters {
  margin: 0px;
  padding: 0px;
}

.MuiListItem-gutters {
  padding: 0px;
}

div ul li .MuiListItemIcon-root {
  min-width: 40px;
}

.MuiList-padding {
  height: auto;
  width: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

nav ul.nav li a:hover,
nav ul.nav li .nav-item:hover,
nav ul.nav li a:focus,
nav ul.nav li .nav-item:focus {
  background-color: rgba(158, 158, 158, 0.2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-cartesian-axis-tick {
  font-size: 10px;
  font-family: Poppins, sans-serif;
}

.recharts-label-list text {
  font-size: 12px;
}

.containerTableContract {
  justify-content: center;
  display: flex;
  height: 900px;
  align-items: center;
}

.containerTableContract .tableContract {
  width: 75%;
}

.container {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

// @media (min-width: 1200px){
// .container, .container-lg, .container-md, .container-sm, .container-xl {
//   min-width: 100%;
// }
// }
// .MuiList-padding {
//   height: 70vh;
//   width: 320px;
// }

.contentWithMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.content-contracts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.contentForward {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .table-container {
    width: 1200px !important;
  }
}

.MuiTypography-root {
  color: #212121;
}

.paperContent {
  width: 95%;
  min-height: 90%;
}

.forwardList > .contentForward {
  width: 95%;
  min-height: 20%;
}

.MuiTableContainer-root {
  width: 98%;
}

.labelValue p {
  font-size: 14px;
}

.labelContainer {
  display: flex;
}

.inputContainer {
  width: 94%;
  height: 40px;
  display: flex;
  align-items: center;
}

.iconSearch {
  width: 4%;
}

.iconSearch svg {
  font-size: 20pt;
}

.relationshipContract {
  width: 98%;
  box-sizing: border-box;
  padding: 34px 22px 15px;
}

.relationshipContract h2 {
  text-align: start;
}

table tbody tr td {
  font-size: 14px;
}

.margin-top-icon-clock-cedente {
  margin-top: -17px !important;
}

.number {
  font-family: 'Inconsolata', monospace;
  font-weight: 600;

  &.bold {
    font-weight: 900;
  }
}

.text-field-label .MuiInputLabel-outlined {
  color: black !important;
  font-weight: lighter;
  font-size: 18px;
}


.text-field-label .MuiInputBase-root {
  border-radius: 20px !important;
  color: #000 !important;
  font-size: 22px !important;
}

.text-field-label .MuiSelect-root {
  color: #000 !important;
  font-size: 22px !important;
}

.swal2-container {
  z-index: 999999 !important;
}
