.modal-dar-oferta--badge {
  display: inline-block;
  align-items: center;
  padding: 2px 12px;
  border-radius: 15px;
  background-color: #5383fc;
  color: #fff;
  font-size: 13px;
  margin-left: 20px;

  > * {
    &:not(:first-child) {
      margin-left: 7px;
    }
  }

  &.modal-dar-oferta--badge--green {
    background-color: #0d8018;
  }

  &.modal-dar-oferta--badge--red {
    background-color: #f75649;
  }
}

.dar-oferta--container {
  grid-template-columns: 1fr;
}

.container-header-modal-offer {
  display: block;
}

@media (min-width: 900px) {
  .container-header-modal-offer {
    display: inline-flex;
  }
}

@media (min-width: 1100px) {
  .dar-oferta--container {
    grid-template-columns: 1fr 1fr;
  }
}
