.dar-oferta-container-mobile {
  display: none;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  .oferta-atual-mobile {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;

    h2.dar-oferta-title-mobile {
      margin: 0;
      padding: 0;
      font-size: 12px;
      color: #383838;
    }
  }

  .dar-oferta-itemsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .dar-oferta-items-mobile {
    span {
      font-size: 11px;
      color: #383838;
    }

    h2 {
      font-size: 16px;
      color: #383838;
      margin: 0;
      padding: 0;
    }
  }

  .values-wrapper-mobile {
    padding: 0 8px;

    span {
      color: #696969;
      font-size: 10px;
      font-weight: lighter;
      margin: 3px;
    }

    p {
      color: #383838;
      font-size: 10px;
    }
  }

  .currency-input-container-mobile {
    input {
      width: 160px;
      padding: 7px 14px;
      border: 1px solid #b5b5b5;
      border-radius: 10px;
      color: #383838;
      font-family: "Inconsolata", monospace;
      font-size: 32px;
      font-weight: 600;
    }
  }

  .dar-oferta--input-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;

    .dar-oferta--input-wrapper-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 20px;
      border: 1px solid #d8d8d8;
      border-radius: 10px;
      background-color: #fff;
      white-space: nowrap;

      .dar-oferta--input {
        margin: 6px 0;

        * {
          font-family: "Inconsolata", monospace;
          font-weight: 900;
        }

        .MuiInputBase-root {
          border-radius: 10px;
        }
      }

      span {
        display: inline-block;
        margin: 0 12px 0;
        color: #696969;
        font-size: 14px;
      }
    }

    .dar-oferta--input-actions-mobile {
      display: grid;
      gap: 14px;
      margin-left: 16px;

      button.dar-oferta--input--action-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border: none;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0px 3px 20px #b5b5b536;
      }
    }
  }

  .dar-oferta--disclaimer-mobile {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  .dar-oferta--button-mobile {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 10px;
    background-color: #0d8018;
    color: #fff;
    font-weight: 500;

    > span {
      font-size: 13px;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  @media screen and (max-width: 770px) {
    display: flex;
  }
}

.dar-oferta--container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .dar-oferta-accordion-container {
    .leilao--accordion--root {
      background: none;
      width: 100%;

      .leilao--accordion-summary {
        padding: 0;
        background: none;
        min-height: 22px;
        max-height: 22px;
      }

      .leilao--accordion-summary-iccon-button {
        padding: 0;
      }

      .dar-oferta-table-container {
        display: flex;
        justify-content: center;
        width: 100%;
        table {
          border-collapse: collapse;
          tr {
            th {
              text-align: left;
              span {
                color: #343434;
              }
            }
            td {
              text-align: right;
              padding-right: 75px;
              span {
                color: #696969;
              }
            }
          }
        }
      }
    }
  }

  .currency-input-container {
    input {
      width: 160px;
      padding: 7px 14px;
      border: 1px solid #b5b5b5;
      border-radius: 10px;
      color: #383838;
      font-family: "Inconsolata", monospace;
      font-size: 32px;
      font-weight: 600;
    }
  }

  small {
    display: inline-block;
    margin: 5px 14px 0;
  }

  .text--with-icon {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .dar-oferta--wrapper {
    padding: 10px 25px;
    border-radius: 10px;

    &.dar-oferta--wrapper--left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .dar-oferta--bottom {
        display: flex;
        align-items: center;
        margin-top: 50px;

        > * {
          flex: 1;

          &:not(:first-child) {
            padding-left: 40px;
          }
        }

        .dar-oferta--input-container {
          display: flex;
          align-items: center;

          .dar-oferta--input-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 142px;
            padding: 10px 20px;
            border: 1px solid #d8d8d8;
            border-radius: 10px;
            background-color: #fff;
            white-space: nowrap;

            .dar-oferta--input {
              margin: 6px 0;

              * {
                font-family: "Inconsolata", monospace;
                font-weight: 900;
              }

              .MuiInputBase-root {
                border-radius: 10px;
              }
            }

            span {
              display: inline-block;
              margin: 0 12px 0;
              color: #696969;
              font-size: 14px;
            }
          }

          .dar-oferta--input-actions {
            display: grid;
            gap: 14px;
            margin-left: 16px;

            button.dar-oferta--input--action {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 11px 16px;
              border: none;
              border-radius: 10px;
              background-color: #fff;
              box-shadow: 0px 3px 20px #b5b5b536;
            }
          }
        }

        .dar-oferta--button {
          width: 100%;
          padding: 17px 22px;
          border: none;
          border-radius: 15px;
          background-color: #0d8018;
          color: #fff;
          font-weight: 500;

          > span {
            font-size: 18px;
          }

          &:disabled {
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12);
          }
        }
      }
    }

    &.dar-oferta--wrapper--right {
      display: grid;
      gap: 20px;
      padding: initial;
      border-radius: initial;

      > * {
        padding: 10px 25px;
        border-radius: 10px;
      }

      .dar-oferta--bottom {
        padding-top: 0;
        margin-top: 10px;

        .dar-oferta--info-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > * {
            &:not(:first-child) {
              margin-left: 20px;
            }
          }

          .dar-oferta--info {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;

            > * {
              &:not(:first-child) {
                margin-left: 15px;
              }
            }

            .dar-oferta--info--letter {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 65px;
              width: 65px;
              border-radius: 20px;
              background-color: #ffffff;
              font-size: 34px;
              font-weight: 700;
            }

            .dar-oferta--info--titles {
              display: grid;
              gap: 1px;

              .dar-oferta--info--title {
                color: #383838;
                font-size: 20px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .dar-oferta--info--subtitle {
                color: #797979;
                font-size: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .dar-oferta--rating-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          padding: 0 4px;

          > * {
            &:not(:first-child) {
              margin-left: 50px;
            }
          }

          .dar-oferta--rating--item {
            display: grid;
            gap: 7px;
            justify-items: flex-start;

            .dar-oferta--rating--item--title {
              font-size: 18px;
              color: #909090;
            }

            .dar-oferta--rating {
              padding: 1px 10px;
              border-radius: 15px;
              color: #fff;
              text-transform: uppercase;
              font-weight: 600;
              font-size: 15px;
              white-space: nowrap;
            }

            .dar-oferta--item--content {
              display: flex;
              align-items: center;
              color: #343434;
              font-weight: 600;

              > * {
                &:not(:first-child) {
                  margin-left: 12px;
                }
              }
            }

            .dar-oferta--item--button {
              padding: 0px;
            }
          }

          .dar-oferta--tags {
            line-height: 19px;
            // This will limit lines in most browsers
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
            // This is a fallback to IE and older versions of other browsers
            max-height: 40px;

            * {
              color: #909090;
              font-size: 14px;
              font-weight: 400;
            }

            .dar-oferta--tags-title {
              color: #4e4e4e;
              font-weight: 500;
            }
          }
        }
      }
    }

    h2.dar-oferta--title {
      margin: 0;
      padding: 0;
      font-size: 26px;
      color: #383838;
      text-align: center;
    }

    .dar-oferta--items {
      display: grid;
      gap: 2px;

      &:not(:first-child) {
        margin-top: 15px;
      }

      .dar-oferta--item {
        display: grid;
        grid-template-columns: 1fr auto 180px;
        margin: 1px 0;

        &.between {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        > * {
          &:last-child {
            display: flex;
            justify-content: flex-end;
          }
        }

        &.dar-oferta--item--big {
          margin: 10px 0;

          * {
            color: #333333;
            font-size: 24px;
            font-weight: 600;
          }

          .dar-oferta__item__align-right {
            span {
              &.value {
                font-size: 24px;
                font-weight: 600;
              }
            }
          }
        }

        .dar-oferta__item__align-right {
          position: relative;

          span {
            position: absolute;
            top: -24px;
            right: 0;
            font-size: 14px;
            font-weight: 400;

            &.value {
              position: relative;
              top: initial;
              right: initial;
              font-size: 16px;
            }
          }
        }

        .month_align_right {
          position: absolute;
          bottom: -14px;
          font-size: 14px;
          font-weight: 400;
        }

        * {
          color: #333333;
          font-size: 16px;
        }

        > span {
          display: flex;
          align-items: center;

          > * {
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .dar-oferta--bottom {
      margin-top: 20px;
    }
  }

  .dar-oferta__item__align-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media screen and (max-width: 770px) {
    display: none;
  }
}
