.leilao-card-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 615px;
  padding: 12px 14px;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  background-color: #fff;
  transition: all 0.1s ease-out;

  &:hover {
    border: 1px solid #585858;
  }

  .leilao-card--content {
    flex: 1;
  }

  .leilao-card--top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leilao-card--top--badges {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      gap: 5px;
    }
  }

  .leilao-card--badge {
    display: flex;
    align-items: center;
    padding: 2px 12px;
    border-radius: 15px;
    background-color: #5383fc;
    color: #fff;
    font-size: 13px;
    white-space: nowrap;

    span {
      font-size: 13px;
    }

    > * {
      &:not(:first-child) {
        margin-left: 7px;
      }
    }

    &.leilao-card--badge--green {
      background-color: #0d8018;
    }

    &.leilao-card--badge--red {
      background-color: #f75649;
    }
  }

  .leilao-card--id {
    color: #bababa;
    font-size: 18px;
    font-weight: 500;
  }

  .leilao-card--info-container {
    display: grid;
    gap: 20px;
    margin-top: 15px;
    padding: 0 10px;

    .leilao-card--info {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      > * {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }

      .leilao-card--info--letter {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 58px;
        width: 58px;
        border-radius: 20px;
        background-color: #f4f4f4;
        font-size: 30px;
        font-weight: 700;
      }

      .leilao-card--info--titles {
        display: grid;
        gap: 1px;

        .leilao-card--info--title {
          color: #383838;
          font-size: 20px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
        }

        .leilao-card--info--subtitle {
          color: #797979;
          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
        }
      }
    }
  }

  .leilao-card--rating-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 4px;
  }

  .leilao-card--rating--item {
    display: grid;
    gap: 7px;
    justify-items: flex-start;

    &.leilao-card--rating--item--align-right {
      text-align: right;
      justify-items: flex-end;
    }

    .leilao-card--rating--item--title {
      font-size: 18px;
      color: #909090;
    }

    .leilao-card--rating {
      padding: 1px 10px;
      border-radius: 15px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 15px;
    }

    .leilao-card--item--content {
      display: flex;
      align-items: center;
      color: #343434;
      font-weight: 600;

      > * {
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }

    .leilao-card--item--button {
      padding: 0px;
    }
  }

  .leilao-card--content-gray {
    display: grid;
    gap: 7px;
    margin: 25px 4px 0;
    padding: 10px 12px;
    border-radius: 10px;
    background-color: #fafafa;

    .leilao-card--content-gray--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #696969;

      .leilao--accordion--root {
        background: none;
        width: 100%;

        .leilao--accordion-summary {
          padding: 0;
          background: none;
          min-height: 22px;
          max-height: 22px;
        }

        .leilao--accordion-summary-iccon-button {
          padding: 0;
        }

        .leilao-accordion-details {
          padding: 0;
        }

        table {
          width: 100%;
          tr {
            th {
              span {
                color: #343434;
              }
            }
            td {
              span {
                color: #696969;
              }
            }
          }
        }
      }

      * {
        font-size: 15px;
      }

      &.leilao-card--content-gray--item--title {
        color: #343434;
        font-weight: 600;

        * {
          font-size: 22px;
        }

        .leilao-card--content-gray--item--value {
          font-size: 22px;
        }
      }

      .leilao-card--content-gray--item--value {
        font-size: 16px;
        text-align: right;
      }
    }
  }

  .leilao-card--current-offer {
    margin: 10px 4px;
    padding: 10px 12px 14px;
    border-radius: 10px;

    h4 {
      margin: 0;
      padding: 0;
      color: #696969;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }

    .leilao-card--current-offer--items {
      display: grid;
      gap: 5px;
      margin-top: 10px;

      .leilao-card--current-offer--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #383838;
        font-weight: 600;

        &.leilao-card--current-offer--item--main {
          * {
            font-size: 22px;
          }
        }

        * {
          font-size: 15px;
        }

        .leilao-card--current-offer--item---text {
          display: flex;
          align-items: center;
          color: #4e4e4e;
          font-weight: 500;
          text-align: right;

          > * {
            &:not(:first-child) {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .leilao-card--buttons {
    display: flex;
    margin-top: 20px;

    > * {
      flex: 1;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    .leilao-card--button {
      padding: 12px;
      background-color: #0d8018;
      border: none;
      border-radius: 15px;
      color: #fff;
      font-size: 18px;
    }
  }

  .leilao-card--tags {
    line-height: 19px;
    // This will limit lines in most browsers
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    // This is a fallback to IE and older versions of other browsers
    max-height: 40px;

    * {
      color: #909090;
      font-size: 14px;
    }

    .leilao-card--tags-title {
      color: #4e4e4e;
      font-weight: 500;
    }
  }
}
