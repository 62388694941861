.dropdown {
  min-width: 100px;
}

.drawer-mobile {
  display: none;
}

.dropdown__arrow--box {
  display: flex;
}

.outside__click--wraper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.company__name {
  color: #727272;
  margin-top: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 10ch;
}

.company__document {
  color: #727272;
  padding-bottom: 16px;
  border-bottom: 1px solid #E0E0E0;
  font-size: 12px;
  text-align: left;
  display: flex;
}

.dropdown-items {
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 2px 6px #00000026;
  width: 400px;
  top: -200px;
  max-height: 650px;
  position: absolute;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  // padding-right: 16px;
  padding: 2px 0px 0px 16px;
  border-radius: 8px;
  z-index: 200;
}

.dropdown__link:hover {
  background:#E0E0E0 0% 0% no-repeat padding-box;
}

.dropdown-item {
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}

.isVisible {
  visibility: visible;
}
.isHidden {
  visibility: hidden;
}

.side-menu-container {
  .side-menu-mobile__right {
    display: none;
  }

  .side-menu-app-bar {
    box-shadow: 0px 3px 20px #00000014;

    .side-menu-toolbar {
      padding: 0 16px;
    }

    .side-menu-icon-button-container {
      width: 72px;

      .side-menu-icon-button {
        position: relative;

        &.side-menu-icon-button--menu {
          &:after {
            content: "";
            position: absolute;
            bottom: 22px;
            left: 17px;
            height: 4px;
            width: 10px;
            background: inherit;
          }
        }

        .side-menu-icon-root--menu {
          font-size: 40px;
        }

        .side-menu-icon-root--chevron {
          height: 33px;
          width: 33px;
          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
    }

    .side-menu--right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      color: #fff;
      font-weight: 600;
      font-family: "Poppins, sans-serif" !important;
      }

      .side-menu--right--item--container {
        display: flex;
        align-items: center;

        .side-menu--right--item {
          display: flex;
          align-items: center;

          h6 {
            margin-left: 8px;
            padding: 0;
            color: #fff;
            font-size: 18px;
          }
        }

        .side-menu--right--item--divider {
          margin: 0 20px;
          height: 35px;
          width: 1px;
          background-color: var(--secondary);
        }
      }
    }
  }

  .side-menu-list.side-menu-list-custom {
    padding: 20px 0;

    .side-menu-list--list-item--container {
      padding: 0 8px;

      &:not(:first-child) {
        margin-top: 10px;
      }

      a {
        display: flex;
        align-items: center;

        &.side-menu-list-link--open {
          background-color: var(--secondary);
          border-radius: 15px;
        }

        .side-menu-list--icon {
          min-width: initial;
        }

        .side-menu-list--list-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 62px;
          width: 56px;
          padding: 11px 16px;
          border-radius: 15px;

          &.side-menu-list--list-item--active {
            background-color: var(--secondary);
          }

          &.side-menu-list--list-item--open {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .side-menu-list--list-item-text {
          display: flex;
          align-items: center;
          height: 49px;
          margin: 0;
          padding: 12px 12px 12px 0;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          opacity: 0;
          transition: opacity 0.1s ease-out;

          * {
            font-size: 18px;
          }

          &.side-menu-list--list-item-text--active {
            * {
              font-weight: 600;
            }
          }

          &.side-menu-list--list-item-text--open {
            opacity: 1;
          }
        }
      }
    }
  }


@media screen and (max-width: 770px) {
  .drawer-desktop {
    display: none;
  }

  .drawer-mobile {
    display: block;
    margin-top: 64px;

    .MuiBackdrop-root {
      margin-top: 64px;
    }

    .MuiPaper-root {
      height: 100%;
      margin-top: 64px;
    }

    .drawer-mobile__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 2px 16px;
      min-height: 67px;
      border-bottom: 1px solid #eaeaea;

      .drawer-mobile__item__right {
        display: flex;
        align-items: center;
      }

      .drawer-mobile__item__left {
        display: flex;
        align-items: center;

        .drawer-mobile__item__left__icon-container {
          padding: 11px 16px;
        }
      }

      .side-menu-list--icon {
        min-width: initial;
      }

      .side-menu-list--list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 62px;
        width: 56px;
        padding: 11px 16px;
        border-radius: 15px;

        &.side-menu-list--list-item--open {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .side-menu-list--list-item-text {
        display: flex;
        align-items: center;
        height: 49px;
        margin: 0;
        padding: 12px 12px 12px 0;
        opacity: 1;

        * {
          font-size: 18px;
        }

        .MuiTypography-root {
          color: inherit;
        }
      }
    }
  }

  body .app-header-logo-img {
    margin-left: 0;
  }

  .side-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1500;
  }

  .side-menu-container .side-menu-app-bar .side-menu-toolbar {
    align-items: center;
    justify-content: space-between;
  }

  .side-menu-container .side-menu-mobile__right {
    display: block;
  }

  .side-menu-container .side-menu-app-bar .side-menu--right {
    display: none;
  }

  .side-menu-container .side-menu-app-bar .side-menu-icon-button-container {
    display: none;
  }

  .side-menu-container .mobile__right {
    display: block;
  }

  .app-content.app-content-vitrine {
    margin: 0;
  }

  .app-page-title.app-page-title--vitrine {
    margin-top: 64px;
    padding: 20px 15px;
  }

  .leilao .search-form-vitrine .search-form-vitrine--top {
    > * {
      display: none;

      &.search-form-vitrine--top--search-container {
        display: block;
        width: 100%;
      }
    }
  }

  .leilao .search-form-vitrine .search-form-vitrine--input.search-form-vitrine--search {
    width: 100%;
    min-width: initial;
  }

  .leilao .search-form-vitrine .search-form-vitrine--bottom {
    display: none;
  }

  .leilao .search-form-vitrine {
    gap: 0;
  }

  .leilao .leilao-content {
    padding: 15px 10px;
  }

  .leilao .leilao-content .leilao-content--top {
    display: none;
  }

  .leilao .leilao-content .leilao-content--grid {
    margin-top: 0;
  }

  .leilao .search-form-vitrine .search-form-vitrine--accordion {
    display: none;
  }

  .leilao .search-form-vitrine .search-form-vitrine--button {
    display: none;
  }

  .leilao .search-form-vitrine__button-mobile {
    display: block;
  }
}
