.modal-dar-oferta--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dar-oferta {
  .modal-dar-oferta--tabpanel {
    height: 100%;
  }

  .modal-dar-oferta--paper {
    display: flex;
    flex-direction: column;
    height: 85vh;
    width: 100%;
    max-width: 85vw !important;
    border-radius: 15px;
  }

  .modal-dar-oferta--top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;

    .modal-dar-oferta--top--left {
      display: flex;
      align-items: center;

      > * {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }

      h1.modal-dar-oferta--title {
        margin: 0;
        padding: 0;
        color: #383838;
        font-size: 38px;
      }

      .modal-dar-oferta--badge {
        display: flex;
        align-items: center;
        padding: 2px 12px;
        border-radius: 15px;
        background-color: #5383fc;
        color: #fff;
        font-size: 13px;

        > * {
          &:not(:first-child) {
            margin-left: 7px;
          }
        }

        &.modal-dar-oferta--badge--green {
          background-color: #0d8018;
        }

        &.modal-dar-oferta--badge--red {
          background-color: #f75649;
        }
      }
    }
  }

  .modal-dar-oferta--tabs--container {
    padding: 0 10px;
    border-bottom: 1px solid #d0d0d0;

    .modal-dar-oferta--indicator {
      height: 6px;
      transform: scale(0.8);
    }

    .modal-dar-oferta--tab-root {
      border-radius: 10px;
      margin-bottom: 5px;
      color: #383838;
      font-size: 24px;

      &.Mui-selected {
        background-color: #effff1;
        font-weight: 600;
      }
    }
  }

  .modal-dar-oferta--content {
    flex: 1;
    padding: 30px 35px;
    overflow: auto;

    &.modal-dar-oferta--content--grey {
      background-color: #fafafa;
    }
  }

  @media screen and (max-width: 1332px) {
    .modal-dar-oferta--paper {
      max-width: 100vw !important;
    }
    .modal-dar-oferta--content {
      padding: 5px 10px;
    }
  }

  @media screen and (max-width: 770px) {
    .modal-dar-oferta--container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
    }
    .modal-dar-oferta--tabpanel {
      height: 100%;
    }

    .modal-dar-oferta--paper {
      margin: 0px;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 64px);
      max-width: 100vw !important;
      border-radius: 0;
    }

    .modal-dar-oferta--top {
      padding: 5px 15px;

      .modal-dar-oferta--top--left {
        display: flex;
        align-items: center;

        > * {
          &:not(:first-child) {
            margin-left: 15px;
          }
        }

        h1.modal-dar-oferta--title {
          font-size: 18px;
        }

        .modal-dar-oferta--badge {
          font-size: 10px;

          > * {
            &:not(:first-child) {

            }
          }

          &.modal-dar-oferta--badge--green {

          }

          &.modal-dar-oferta--badge--red {

          }
        }
      }
    }

    .modal-dar-oferta--tabs--container {


      .modal-dar-oferta--indicator {

      }

      .modal-dar-oferta--tab-root {
        &.Mui-selected {
        }
      }
      .modal-dar-oferta-tab-lastros {
        display: none;
      }
      .modal-dar-oferta-tab-titulo {
        display: none;
      }
    }

    .modal-dar-oferta--content {


      &.modal-dar-oferta--content--grey {

      }
    }
  }
}
