.containerTitle {
    width: 88%;
    padding: 0 10px;
}
.searchForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: white;
    width: 95%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    .item {
        display: flex;
    }
    min-width: 430px;
}
.tableContainerInvoice {
    margin-top: 32px;
    height: 700px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.MuiInputBase-adornedEnd {
    border: solid 1px #cbcbcb!important;
    padding: 12px!important;
    border-radius: 6px!important;
    width: 100%!important;
}

.containerDatePicker .MuiInputLabel-shrink {
    margin-top: 0!important;
}

.itemPicker {
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    align-items: center;

}
.btnContainer    {
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
button {
    
    width: 105px;
    border: 0;
    border-radius: 6px;
    background-color: black;
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    height: 36px;

}
}
.containerPagination {
    padding: 18px;
    border-top: solid #dcdef1 1px;
}
.containerDatePicker {
    display: flex;
    justify-content: center;
    width: 80%;
    align-items: center;

    .MuiInput-underline {
        padding: 8px;
        border: solid 1px #c4c4c4!important;
        border-radius: 6px!important;
        width: 100%;
    }
    
}
.MuiMenu-list {
    padding: 0!important;
}
.MuiMenu-list option {
    margin: 0!important;
    padding-top: 4px!important;
    padding-left: 16px!important;
    cursor: default!important;
}
.MuiMenu-list option:hover {
    background-color: #6a647744;
}
.MuiInputBase-root {

    display: flex;
    justify-content: center;
    // width: 88% !important;
    align-items: center;
}
.parceiroInput .MuiInputBase-root {
    height: 91%!important;
}
.MuiFormControl-marginNormal {
    margin-top: 9px!important;
}

.table {
    min-width: 890px;
}

th {
    cursor: pointer;
    padding-left: 0px;
    padding-right: 0px;
}

.table td, .table th {
    padding-left: 8px!important;
    padding-right: 8px!important;
    // max-width: 112px!important;
    min-width: 112px!important;
}


tr th.typeIconHeader, tr td.typeIconCell {
    // max-width: 64px!important;
    min-width: 64px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-right: 0px!important;
}

tr th.company, tr td.company {
    // max-width: unset!important;
    // min-width: unset!important;
    max-width: 356px!important;
    min-width: 356px!important;
    padding-left: 8px!important;
    padding-right: 8px!important;
}

tr td div.typeIcon {
    width: 40px!important;
    height: 40px;
    border-radius: 8px;
    padding: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5em;
}



.table thead th.ultima-atualizacao {
    max-width: 128px;
    white-space: normal;
}

tr td.cnpj {
    max-width: unset!important;
    margin: 0px!important;
    padding-left: 0px!important; 
    padding-right: 0px!important; 
}

@media (max-width: 770px) {
    .MuiInputLabel-root	{
        font-size: .75em!important;
        max-width: 90%!important;
    }
}