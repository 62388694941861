.containerTitle {
  width: 88%;
  padding: 0 10px;
}
.searchForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
  width: 95%;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
  margin: 0 auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  .item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    div {
      label {
        margin-top: -4px !important;
      }
      label.ml-2.MuiInputLabel-shrink {
        margin-top: unset !important;
      }
      label.ml-2 {
        margin-top: 8px !important;
      }
    }
  }
  min-width: 430px;
}

.text-field div {
  height: 48px !important;
}

.status-wrapper {
  display: flex;
  align-items: center;

  gap: 1em;

  .text-field-select div {
    min-width: 15em !important;
  }
}

.tableContainerInvoice {
  margin-top: 32px;
  height: 700px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  div {
    width: 100% !important;
  }

  .paginacao {
    div {
      width: auto !important;
    }
  }
}

.MuiInputBase-adornedEnd {
  border: solid 1px #cbcbcb !important;
  padding: 12px !important;
  border-radius: 6px !important;
  width: 100% !important;
}

.containerDatePicker .MuiInputLabel-shrink {
  margin-top: 0 !important;
}

.itemPicker {
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}
.btnContainer {
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  button {
    width: 105px;
    border: 0;
    border-radius: 6px;
    background-color: black;
    color: white;
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    height: 36px;
  }
}
.containerPagination {
  padding: 18px;
  border-top: solid #dcdef1 1px;
}
.containerDatePicker {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  align-items: center;
  margin: 0 8px;

  .MuiInput-underline {
    padding: 8px;
    border: solid 1px #c4c4c4 !important;
    border-radius: 6px !important;
    width: 100%;
  }
}
.MuiMenu-list {
  padding: 0 !important;
}
.MuiMenu-list option {
  margin: 0 !important;
  padding-top: 4px !important;
  padding-left: 16px !important;
  cursor: default !important;
}
.MuiMenu-list option:hover {
  background-color: #6a647744;
}
.MuiInputBase-root {
  display: flex;
  justify-content: center;
  // width: 88% !important;
  align-items: center;
}
.parceiroInput .MuiInputBase-root {
  height: 91% !important;
}
.MuiFormControl-marginNormal {
  margin-top: 9px !important;
}

th {
  cursor: pointer;
}

.table thead th.ultima-atualizacao {
  max-width: 128px;
  white-space: normal;
}

.table-resposive {
  width: 100%;
}

@media (max-width: 770px) {
  .MuiInputLabel-root {
    font-size: 0.75em !important;
    max-width: 90% !important;
  }
}
