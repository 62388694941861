@import 'App.scss';
.containerSearch {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    align-content: center;
}

// .content {
//     max-width: none!important;
// }

.contentBody {

    .MuiPaper-root, .MuiCard-root, .card-box .mb-4 ,.MuiPaper-elevation1 ,.MuiPaper-rounded {
        width: 100%;
        .tableContainer {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .tableContainer, table {
            width: 100%;
        }
    }
}

.containerSearch .titleCotacao {

    padding: 5px;
    width: 26%;
}
.containerSearch .titleCotacao h3  {
    font-size: 24px;
    color: black;
}

.roxo {

    border-bottom: solid 3px #7d76ff!important;
}
.roxoSvg {
    color:  #7d76ff;
}

// table tbody tr td a {
// text-decoration: none;
// color: black;
// font-size: 12px;
// }
// table tbody tr td  input {
//     width: 67%;
//     height: 46px;
//     background: #efefef;
//     border: #9a9999 1px solid;
// }
.antecipaGov {
    height:90vh;
    width: 95%;
    margin: 0 auto;
    margin-left: 3.125%;
    max-width: 1230px!important;
    table {
        thead {
            border-bottom: solid #e0e0e0 2px; 
        }
    }
}
.antecipaGov .MuiTableCell-root {
    padding:6px
}

input[name="pmr"], input[name="pmp"] {
    text-align: right;
} 

.paper {
        min-height: 71px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        background: white;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12);
        margin: 20px 0;
        width:100%;
        
       .itemIncludeTitle {
           width: 161px;
           display: flex;
           justify-content: space-between;
        }
        .containerSearch .inputContainer {
        display: flex;
        justify-content: center;
        width: 40%;
        height: 54px;
        align-items: center;
    }
    
     .containerSearch .inputTable {
        width: 73%;
        height: 40px;
        border-bottom: solid 3px black;
        display: flex;
        align-items: center;
    }
    .containerSearch .inputTable input {
        width: 94%;
        height: 100%;
        outline: none;
        border: none;
    }
    .containerSearch .inputTable  .iconSearch {
        width: 17%;
    }
        h2 {
            font-size: 18px;
            color: black;
            padding: 20px 10px 5px;
        }
        h3 {
            font-size: 14px;
            padding: 2px 25px;
        }
        .inputContainer  {
            width: 94%;
            height: 90%;
        }
        .inputContainer input {
            width: 96%;
            height: 100%;
            outline: none;
            border: none;
        }
        .containerOut {
            padding: 12px 17px!important;
        }
     
}


.MuiInputBase-root, .MuiTablePagination-input, .MuiTablePagination-selectRoot {
    // width: 21%;
}
td {
    input {
        height: 45px;
        border-radius: 8px;
        border: 1px solid gray;
        text-align: right;
    }
}
.MuiPaper-elevation1 {
    box-shadow: 0 0 black!important;
}
.MuiInputBase-adornedEnd {
    border: solid 1px #cbcbcb;
    padding: 12px;
    border-radius: 6px!important;
    width: 300px!important;
}
.calculator {
    width: 32px;
}

.MuiPaper-root.app-page-title--iconbox {
    display: none!important;
}

.page-title {
    width: 92%;
    padding: 0 15px;
    margin: 0 auto;
    min-width: 430px;
    // box-sizing: border-box;
    
    .app-page-title {
        max-width: 1230px!important;
    }
}

.containerTitle {
    width: 88%;
    padding: 0 10px;
}
.searchForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: white;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    .item {
        display: flex;
    }
    min-width: 430px;
    margin-bottom: 32px;
}
.tableContainerInvoice {
    margin-top: 32px;
    height: 700px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.MuiInputBase-adornedEnd {
    border: solid 1px #cbcbcb!important;
    padding: 12px!important;
    border-radius: 6px!important;
    width: 100%!important;
}

.containerDatePicker .MuiInputLabel-shrink {
    margin-top: 0!important;
}

.containerDatePicker .date-picker [data-shrink="false"] {
    margin-left: 16px;
    margin-top: 8px;
}

.itemPicker {
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    align-items: center;

}
.btnContainer    {
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
button {
    
    width: 164px;
    border: 0;
    border-radius: 6px;
    background-color: black;
    color: white;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .95em;
    height: 36px;

}
}
.containerPagination {
    padding: 18px;
    border-top: solid #dcdef1 1px;
}
.containerDatePicker {
    display: flex;
    justify-content: center;
    width: 80%;
    align-items: center;

    .MuiInput-underline {
        padding: 8px;
        border: solid 1px #c4c4c4!important;
        border-radius: 6px!important;
        width: 100%;
    }
    
}
.MuiMenu-list {
    padding: 0!important;
}
.MuiMenu-list option {
    margin: 0!important;
    padding-top: 4px!important;
    padding-left: 16px!important;
    cursor: default!important;
}
.MuiMenu-list option:hover {
    background-color: #6a647744;
}
.MuiInputBase-root {

    display: flex;
    justify-content: center;
    // width: 88% !important;
    align-items: center;
}
.parceiroInput .MuiInputBase-root {
    height: 91%!important;
}
.MuiFormControl-marginNormal {
    margin-top: 9px!important;
}

th {
    cursor: pointer
}

.table thead th.ultima-atualizacao {
    max-width: 128px;
    white-space: normal;
}

html {
    scroll-behavior: smooth;
}

.tabs-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

a.link-bitrix {
    // padding-left: 10px;
}

.relacionados .MuiFormLabel-root {
    font-size: 12.25px!important;
}

.separation-bar {
    // background-color: #b4b4b4;
    background-image: linear-gradient(to right, rgba(233, 233, 233, 0.596), #d6d7e6, rgba(233, 233, 233, 0.596));
    height: 1px;
    width: 100%;
    border-radius: 1px;
    margin: 16px 0;
}

.divisorContas {
    // background-color: #222222;
    background-image: linear-gradient(to right, rgba(233, 233, 233, 0.596), #d6d7e6, rgba(233, 233, 233, 0.596));
    height: 100%;
    width: 2px;
    border-radius: 1px;
    margin: 16px 0; 
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
    height: 48px!important;
    margin-top: 16px!important;
}

.user-email {
    textarea {
        border: none;
        resize: none;
        overflow: hidden;
        height: 1.7em;
        width: auto;
        cursor: pointer;
        max-width: 75%;
        background-color: #f5f5f7 !important;
    }
    span {
        padding-bottom: 3px;
    }
}

@media (max-width: 954px) {
    .participacao-empresa .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined{
        margin-top: -4px!important;
    }
}
@media (max-width: 770px) {
    .MuiInputLabel-root	{
        font-size: .75em!important;
        max-width: 90%!important;
    }
    .d-flex.responsible {
        flex-direction: column!important;
    }
}