.container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 40px;
  border-radius: 0.5rem;
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  background: white;
  padding: 40px;
  border-radius: 0.5rem;
  width: 100%;
}
