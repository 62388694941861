.prorrogacao-size .PrivateTabIndicator-root-12.PrivateTabIndicator-colorPrimary-13.MuiTabs-indicator {
  width: 100% !important;
}

.MuiTabs-root.prorrogacao-size {
  display: none;
}

.timeline-item--content h3,
.timeline-item--content p {
  background-color: #fafafa;
  margin-left: -18px !important;
  padding-left: 18px !important;
  padding-right: 64px;
}

.timeline-item--content h3 {
  font-size: 1.1em !important;
  border-radius: 0px 8px 0px 0px;
}

.timeline-item--content p {
  border-radius: 0px 0px 8px 0px;
  padding-bottom: 16px;
}

.notification-container {
  .notification-button {
    position: relative;

    .notification-badge {
      position: absolute;
      top: 10px;
      right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 21px;
      width: 21px;
      background-color: #b5ffd4;
      color: #1f1f1f;
      font-size: 12px;
    }
  }
}
