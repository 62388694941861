main#risco {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  flex: 5;

  gap: 24px;

  font-family: 'Poppins', sans-serif !important;

  .content {
    display: flex;
    justify-items: flex-start;

    box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  aside {
    display: flex;
    justify-content: flex-start;
    flex: 2;

    &.container-button {
      button {
        box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);
        width: 200px;
      }
    }
  }
}
