.container--title {
  .textField--titles--root {
    fieldset {
      border-radius: 15px;
    }
    input {
      color: black;
    }
    label {
      color: #383838;
    }
  }
}
.vitrine--container--dotted {
  text-align: center;
  padding: 10px;
  width: 60%;
  border: 2px dashed #b5b4b4;
  span {
    font-size: 20px/30px;
    font-style: normal;
  }
  h1 {
    text-align: center;
    font-size: 40px/60px;
    color: #0e8018;
    padding: 0px;
  }
}
#titulo .MuiInputBase-adornedEnd {
  border: none !important;
  padding: 0px !important;
}
