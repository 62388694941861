main#vencimento-main {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Poppins', sans-serif;

  width: 100%;

  gap: 48px;

  .text-container {
    height: 90px;

    display: flex;
    justify-content: center;
    align-items: center;

    .MuiFormControl-root,
    .MuiTextField-root,
    .MuiFormControl-marginNormal {
      margin: 0px !important;
      padding: 0px;
      .MuiInputBase-adornedEnd,
      .MuiInputBase-formControl {
        width: auto !important;
        max-width: 100px;
      }
    }
  }

  .container {
    height: 90px;

    display: flex;
    justify-content: center;
    align-items: center;

    .MuiFormControl-root,
    .MuiTextField-root,
    .MuiFormControl-marginNormal {
      margin: 0px !important;
      padding: 0px;
      .MuiInputBase-adornedEnd,
      .MuiInputBase-formControl {
        width: auto !important;
        max-width: 194px;
      }
    }
  }

  .content {
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;

    flex: 5;

    gap: 4px;

    max-height: 225px;
    padding: 0 20px;
    padding-bottom: 20px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    strong {
      width: 2em;
    }

    .vencimento-item {
      display: flex;
      align-items: center;
      gap: 10px;

      padding: 0 12px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
    }
  }

  aside {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    flex: 3;

    width: auto;

    .total {
      padding: 0 24px;
      border-radius: 8px;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);

      h4 {
        font-family: 'Nunito', sans-serif;
      }

      p {
        font-size: 24px;
        color: #888;
        font-weight: 500;

        > span {
          font-size: 24px;
          font-weight: 400;
          color: #37d05a;
        }
      }
    }
    &.container-button {
      button {
        box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);
        width: 160px;
        padding: 27px 0;
      }
    }
  }
}

.days-to-go-extension {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 48px;
  }
}

#extension-date-label {
  width: 200px;
}
