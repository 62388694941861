.react-file-reader {
  width: 100%;
  height: 100%;
}
.react-file-reader-button {
  width: 100%;
  height: 100%;
  padding: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.react-file .react-file-reader-button {
  padding-bottom: unset;
}
