.tags-container {
  min-width: 35em !important;
  max-width: 35em !important;

  .tag-items {
    display: flex;

    gap: 4em;

    // tag
    .MuiAutocomplete-tag {
      background: #242222;
      color: white;
    }

    // botao x da tag
    .MuiAutocomplete-tag svg {
      fill: white;
    }

    justify-content: center;
    align-content: center;

    .tag-item div div {
      font-weight: 600;
    }
  }
}
