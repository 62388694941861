.cedente--container {
  display: flex;
  height: 100%;
  overflow: auto;

  .cedente--left {
    min-width: 220px;
    padding: 2px 20px 2px 2px;
    position: sticky;
    top: 0;

    .cedente--left--buttons {
      display: grid;
      gap: 15px;
    }
  }

  .cedente--right {
    width: 100%;
    padding: 0 50px;
    border-left: 1px solid #d8d8d8;
  }

  .cedente--button {
    display: flex;
    justify-content: flex-start;
    padding: 13px 24px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 0 0 1px #d5d5d5;
    color: #2e2e2e;

    * {
      font-size: 18px;
    }

    &.cedente--button--active {
      color: #1d1d1d;
      font-weight: 600;
    }

    .MuiButton-startIcon {
      margin-right: 14px;
    }
  }
}
