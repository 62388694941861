// Core
body {

  .app-header-logo {
    height: $header-height;
    width: $sidebar-width;
    @include media-breakpoint-down(md) {
      display: none;
    }

    display: flex;
    align-items: center;
    background: rgba(255,255,255,.08);
    padding-left: ($spacer);
    padding-right: ($spacer);
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-header-logo-close {
    width: $sidebar-width-collapsed;
    overflow: hidden;

    .header-logo-wrapper {
      margin: auto;
    }

    .header-logo-text {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      width: 0;
      padding-left: 0;
    }
  }

  .app-header-logo-open {
    background: rgba(255,255,255,.08);
  }

  .app-header-logo-img {
    width: 130px;
    height: auto;
    margin-left: 30px;
  }

  .header-logo-wrapper {
    display: flex;
    align-items: center;
  }

  .header-logo-wrapper-btn {
    padding: ($spacer / 3);
    color: $white;
    background: rgba(255,255,255,.09);

    &:hover {
      background: rgba(255,255,255,.14);
    }
  }

  .header-logo-wrapper-link {
    text-decoration: none;
  }

  .header-logo-text {
    padding-left: $spacer / 2;
    font-size: $font-size-base * 1.1;
    font-weight: bold;
    width: auto;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
