.containerTitleSimulator {
  // background: white;
  // width: 75%;
  // position: absolute;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // top: 50%;
  // border-radius: 15px;
  // padding: 0 38px;
  // height: 95%;
  background: white;
  width: 75%;
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* top: 50%; */
  border-radius: 15px;
  padding: 0 38px;
  height: 95%;
  display: flex;
  flex-direction: column;
  margin: 10px auto;

  .containerHead {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-top: 20px;
    justify-content: space-between;
    h3 {
      font-weight: 600;
      padding: 17px 20px;
    }
    .consultarPreAprovados {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      button.preAprovado {
        height: 38px;
        border-radius: 6px;
        border: transparent;
        color: white;
        background: #c02d25;
        font-weight: 600;
        padding: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
  .containerInputsGroup {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    .containerInput {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 7px;
      label {
        margin: 0px;
        font-size: 14px;
        padding: 5px 0;
      }
      .labelPadding {
        padding: 0px;
      }
      input {
        text-align: right;
        height: 50px;
        border-radius: 7px;
        border: solid 1px #a7a7a7;
        padding: 7px;
        box-sizing: border-box;
        width: 100%;
      }
      input::before {
        border-bottom: none;
      }
      .MuiInputBase-adornedEnd {
        border: none !important;
        padding: 0px !important;
        border-radius: 6px !important;
        width: 300px !important;
      }
    }

    // [name="taxaMensal"],
    // [name="numeroParcelas"] {
    //   width: 90px;
    // }

    .containerInputButton {
      padding: 0px;
      position: absolute;
      top: -56%;
      left: 91%;
      transform: translate(-73%, 3%);
    }

    .MuiFormControl-marginNormal {
      margin: 0 !important;
    }
  }
  .containerGenerateTitle {
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button.gerar-titulo {
      height: 38px;
      border-radius: 6px;
      border: 1px solid #4473c5;
      color: #4473c5;
      background: #fff;
      font-weight: 600;
      padding: 15px 48px;
      display: flex;
      align-items: center;
      margin: 10px;
    }
    .btnCalc {
      height: 38px;
      border-radius: 6px;
      border: transparent;
      color: white;
      background: #4473c5;
      font-weight: 600;
      padding: 15px 48px;
      display: flex;
      align-items: center;
    }

    .disabledBtn {
      background: #b3b3b3 !important;
      cursor: not-allowed;
      color: #fff !important;
      border: none !important;
    }
  }
}

.containerInput,
.withoutBorder {
  .MuiInput-underline::before {
    border-bottom: none;
  }
}
.tableParcelContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  // padding: 14px;
  overflow-y: scroll;
  table {
    width: 100%;
    td {
      padding: 10px;
      font-size: 12px;
    }
  }
}
