#proposta-serpro {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;
  // background: #8731aa !important;

  padding: 2em;

  header {
    font-family: Nunito, sans-serif;
    font-weight: bolder;

    span {
      font-size: 24px;
    }
  }

  body {
    padding: 10px 10px;
    margin: 2em 0;
    // grid
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1em;

    border-radius: 8px;
    box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);

    // text-field
    .MuiTextField-root {
      width: 100%;
      border-radius: 8px;
    }

    .containerDatePicker {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      margin-left: -10px;

      border: 0;

      .MuiInput-underline {
        padding: 8px;
        border: 0;
        width: 100%;
      }
    }

    .containerDatePicker .MuiInputLabel-shrink {
      margin-top: 0 !important;
    }
  }
}
