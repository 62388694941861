.container-agenda-row {
  .leilao-card--rating {
    display: inline-block;
    text-align: center;
    padding: 0 18px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 27px;
  }

  .lastrosTextValueNumber {
    font-size: 20px;
  }

  .lastrosTextValue {
    font-size: 20px;
    font-weight: 600;
  }

  .lastrosTextCnpj {
    font-size: 17px;
  }

  .lastrosTextValueSacado {
    font-size: 20px;
    font-weight: 600;
  }

  .buttonRootDossie {
    padding: 15px 35px;
    background-color: #0d8018;
    border: none;
    border-radius: 15px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    svg {
      margin-right: 10px;
    }
  }

  .buttonRootDetails {
    padding: 15px 30px;
    background-color: #ededed;
    border: none;
    border-radius: 15px;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    svg {
      margin-right: 10px;
    }
  }
}
