.formContainer {
  width: 100%;
}

#wrapper {
  display: block;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

  padding: 28px;

  width: 100%;

  border-bottom: 6px solid rgba(0, 0, 0, 0.1);

  #wrapperTitle {
    padding-top: 1em;
    padding: 0 10px;
    margin-bottom: 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 18px;
    font-weight: 600;

    .MuiFormControl-root,
    .MuiTextField-root,
    .MuiFormControl-marginNormal {
      margin: 0px !important;
      padding: 0px;
      .MuiInputBase-adornedEnd,
      .MuiInputBase-formControl {
        width: auto !important;
        max-width: 100px;
      }
    }
  }

  #wrapperBody {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    gap: 12px;

    .MuiFormControl-root,
    .MuiTextField-root,
    .MuiFormControl-marginNormal {
      margin: 0px !important;
      padding: 0px !important;
      .MuiInputBase-adornedEnd,
      .MuiInputBase-formControl {
        width: auto !important;
        max-width: 194px;
      }
    }

    padding: 0 1em;
    & div {
      margin-left: 0;
    }

    &.column {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
