.MuiInputBase-input .MuiInput-input {
  width: 200px;
  font-size: 200px;
  display: flex;
  flex-direction: row;
  background-color: red;
}

.table-responsive { 
  overflow-x: hidden;
}