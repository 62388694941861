.lastro--container {
  .with-back-button {
    .with-back-button--top {
      display: flex;
      align-items: center;

      h1 {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        font-weight: 400;
      }
    }
  }
  .title-lastro {
    font-size: 35px;
    font-weight: 500;
  }
}
