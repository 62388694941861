.leilao {
  .search-form-vitrine {
    display: grid;
    gap: 25px;
    margin-top: 24px;

    .search-form-vitrine--input {
      border-radius: 15px;
      white-space: nowrap;

      &.search-form-vitrine--input--select {
        label {
          &.MuiInputLabel-outlined {
            padding: 0 5px;
            background-color: #fff;
            transform: translate(18px, 20px) scale(1);

            &.MuiInputLabel-shrink {
              transform: translate(18px, -7px) scale(0.75);
            }
          }
        }
      }

      * {
        border-radius: 15px;
        border: none;
      }

      label {
        font-size: 17px;
        color: #383838;

        &.MuiInputLabel-outlined {
          transform: translate(23px, 20px) scale(1);

          &.MuiInputLabel-shrink {
            transform: translate(23px, -7px) scale(0.75);
          }
        }
      }

      input {
        padding: 17.5px 23px;
        color: #404040;
        font-size: 17px;
      }

      &.search-form-vitrine--search {
        min-width: 340px;
        background-color: #f2f2f2;
      }

      .MuiInputBase-root {
        height: 100%;
        width: initial;

        .MuiSelect-root {
          padding: 18.5px 23px;
        }
      }
    }

    .search-form-vitrine--top {
      display: flex;

      > * {
        &:not(:first-child) {
          margin-left: 20px;
        }
      }

      .search-form-vitrine--top--search-container {
        position: relative;
        display: flex;

        > * {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }

      .search-form-vitrine__buttons-group {
        .search-form-vitrine__button-group {
          height: 100%;
          padding: 0 10px;
          border-radius: 0;

          &:nth-child(1) {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }

          &:nth-child(2) {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }

          .search-form-vitrine__button-group__label {
            color: #383838;
            font-size: 17px;
          }

          span.search-form-vitrine__button-group__text {
            span.search-form-vitrine__button-group__count {
              display: inline-block;
              position: relative;
              top: -7px;
              margin-left: 5px;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .search-form-vitrine--bottom {
      display: grid;
      gap: 20px;
      grid-template-columns: 105px 105px 135px 135px 170px auto;

      &.search-form-vitrine--bottom--expanded {
        grid-template-columns: 105px 105px 135px 135px 125px auto;
      }
      @media screen and (max-width: 1336px) {
        gap: 15px;
      }
    }

    .search-form-vitrine--button {
      justify-self: flex-start;
      min-width: 160px;
      border-radius: 15px;
      box-shadow: none;
      white-space: nowrap;

      &.search-form-vitrine--button--more-filters {
        min-width: initial;

        .search-form-vitrine--button--label {
          color: #383838;
        }
      }

      &.search-form-vitrine--button--clear {
        min-width: initial;
        padding: 0;
        background-color: transparent;

        .search-form-vitrine--button--label {
          color: #383838;
        }
      }

      .search-form-vitrine--button--label {
        color: #ffffff;
        font-weight: 500;
      }
    }

    .search-form-vitrine--accordion {
      margin: 0;

      &::before {
        display: none;
      }

      .search-form-vitrine--accordion-summary {
        display: none;
      }

      .search-form-vitrine--accordion-details {
        padding: 0;

        .search-form-vitrine--more-filters {
          display: grid;
          gap: 20px;
          grid-template-columns: 160px 195px 150px 250px;
          width: 100%;

          @media screen and (max-width: 1336px) {
            gap: 15px;
          }
        }
      }
    }
  }

  .leilao-content {
    padding: 20px 50px;
    background-color: #f2f2f2;

    @media screen and (max-width: 1280px) {
      padding: 20px;
    }

    .leilao-content--top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leilao-content--layouts-container {
        display: grid;
        grid-template-columns: auto auto;

        .leilao-content--icon {
          transform: scale(0.6);
          cursor: pointer;
        }
      }

      .leilao-content--filtro {
        display: flex;
        align-items: center;

        label {
          margin-bottom: 0;
          margin-right: 10px;
          color: #383838;
          font-size: 16px;
        }

        .MuiInputBase-root {
          width: 180px;

          &::before {
            display: none;
          }

          .MuiSelect-root {
            position: relative;
            top: 3px;
            color: #383838;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    .leilao-content--grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      gap: 20px;
      justify-content: center;
      margin-top: 22px;

      @media screen and (max-width: 1370px) {
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      }

      @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      }
    }

    .leilao-content--row {
      display: grid;
      gap: 30px;
      margin-top: 22px;
    }
  }

  .search-form-vitrine__range-container {
    display: flex;
    align-items: center;
    padding: 0 40px;
  }

  .search-form-vitrine__button-mobile {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: none;
  }
}
