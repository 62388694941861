.containerDetails {
  margin-top: 20px;
  .headerModalDetail {
    background-color: #fdeddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 22px;
  }

  .headerModalDetailNoBorder {
    background-color: #fdeddd;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 22px;
  }

  .itemLastroDetails {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 20px;
  }

  .lastroDetailsButton {
    padding: 12px;
    background-color: #d5fdd9;
    border: none;
    border-radius: 15px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
  }

  .lastroDetailsTextFieldRoot {
    fieldset {
      border-radius: 15px;
      border: 1px solid red;
    }
    input {
      color: black;
    }
    label {
      color: #383838;
    }
  }

  .lastroDetailsTextFieldWithIconRoot {
    div {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 14px;
    }
    fieldset {
      border-radius: 15px;
      border: 1px solid red;
    }
    input {
      color: black;
    }
    label {
      color: #383838;
    }
  }

  .itensLastroDetails {
    display: flex;
    gap: 25px;
  }

  .itemLastroDetailsSacado {
    gap: 25px;
    padding: 20px 10px;
  }

  .totalMaturitiesContainerDotted {
    border-style: dashed;
    border: 3px dotted #B5B4B4;
    padding: 15px;
    margin-bottom: 10px;
    span {
      font-weight: 400;
      font-size: 16px;
    }
    h1 {
      padding: 0;
      font-size: 40px;
      font-weight: 600;
      color: #0e8018;
    }
  }
}
