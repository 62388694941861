.formContainer {
  background: white;
  box-shadow: 4px 3px 6px 0px #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  padding: 28px;
  border: 1px solid #e4e0e0;

  .containerTitle {
    height: 46px;
  }

  .container {
    height: 90px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 222px;
    .MuiFormControl-root,
    .MuiTextField-root,
    .MuiFormControl-marginNormal {
      margin: 0px !important;
      padding: 0px;
      .MuiInputBase-adornedEnd,
      .MuiInputBase-formControl {
        width: 194px !important;
      }
    }
  }
}

.table th.dateHeader {
  padding-left: 56px !important;
}
